import axios from 'axios'
import Qs from 'qs'


export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function api (method, path, data) {
  const requestData = {
    method: method.toLowerCase(),
    url: apiUrl(path),
    withCredentials: true,
    paramsSerializer: (params) => {
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    },
    validateStatus () {
      return true
    }
  }
  if (requestData.method === 'get' || requestData.method === 'delete') {
    if (!data) {
      data = {}
    }
    requestData.params = { ...data, currentTime: Date.now() }
  } else {
    requestData.data = data
  }
  const res = await axios(requestData)
  return res
}

export function apiUrl (api) {
  return '/api' + api
}
