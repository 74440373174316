import { nextTick } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'

import DocumentConvert from '@/pages/DocumentConvert'
import YuraImportDocument from '@/pages/YuraImportDocument'

const DEFAULT_TITLE = '대문관세법인';

const routes = [
  {
    name: 'DocumentConvert',
    path: '/',
    component: DocumentConvert,
    meta: {
      title: "자료변환 - 대문관세법인",
    },
  },
  {
    name: 'YuraImportDocument',
    path: '/yura/import-document',
    component: YuraImportDocument,
    meta: {
      title: "유라 수입서류조회 - 대문관세법인",
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export { router }
