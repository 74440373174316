<template>
  <a href="#" @click="go" >{{label}}</a>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    label: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  components: {
  },
  methods: {
    go() {
      this.$router.push(this.route)
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  margin-right: 20px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
  }
}
</style>
