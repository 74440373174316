<template>
  <div v-if="user" class="login-form">
    <div class="welcome"><b>{{ user.username }}</b>님 환영합니다</div>
    <button @click="signout">로그아웃</button>
  </div>
  <form v-else class="login-form" @submit.prevent="signin">
    <input type="text" placeholder="사용자 ID" v-model="loginId" />
    <input type="password" placeholder="비밀번호" v-model="loginPassword" />
    <button type="submit">로그인</button>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  components: {
  },
  methods: {
    async signin() {
      const res = await this.api('POST', '/signin', {
        username: this.loginId,
        password: this.loginPassword,
      })
      if (res.status !== 200) {
        alert('로그인 실패')
      }
      await this.$store.dispatch('loadUserInfo')
    },
    async signout() {
      await this.api('POST', '/signout')
      await this.$store.dispatch('loadUserInfo')
    },
  },
  data() {
    return {
      loginId: '',
      loginPassword: '',
    };
  },
}
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  align-items: center;
  & > input {
    margin-right: 10px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  & > button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f8f8f8;
    cursor: pointer;
  }
  & > .welcome {
    margin-right: 10px;
  }
}
</style>
