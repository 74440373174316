<template>
  <Container>
    <div class="page">
      <h1>유라 수입서류조회</h1>
      <div class="import-document" v-for="item in data" :key="item.invoice_no">
        <div class="row">
          <div class="invoice-no">{{ item.invoice_no }}</div>
          <div class="sillar">{{ item.seller }}</div>
        </div>
        <div class="info">
          <div class="shipping-date">선적일: {{ item.shipping_date }}</div>
        </div>
        <div class="documents">
          <FileButton :document="item" filetype="all">ALL</FileButton>
          <FileButton :document="item" filetype="inv">INV</FileButton>
          <FileButton :document="item" filetype="inv_d">INV-D</FileButton>
          <FileButton :document="item" filetype="pack">PACK</FileButton>
          <FileButton :document="item" filetype="processing_price">임가공<br/>단가표</FileButton>
          <FileButton :document="item" filetype="bl">B/L</FileButton>
          <FileButton :document="item" filetype="certificate">면장</FileButton>
          <FileButton :document="item" filetype="insurance">보험</FileButton>
          <FileButton :document="item" filetype="bom1">BOM1</FileButton>
          <FileButton :document="item" filetype="bom2">BOM2</FileButton>
          <FileButton :document="item" filetype="bom_sap">BOM<br/>SAP</FileButton>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { sleep, api, apiUrl } from '@/utils'
import Frame from '@/views/Frame'

import FileButton from './FileButton'

// invoice_no
// creared_at
// seller
// shipping_date
// all_file
// inv_file
// inv_d_file
// pack_file
// processing_price_file
// bl_file
// certificate_file
// insurance_file
// bom1_file
// bom2_file
// bom_sap_file
const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export default {
  name: 'HomeView',
  components: {
    Container: Frame,
    FileButton
  },
  created () {
    this.load()
  },
  methods: {
    async load () {
      const response = await api('GET', '/yura/import/document/list')
      this.data = response.data
    },
    buildDownloadButtonClass (fileId) {
      if (this.isEmpty(fileId)) {
        return 'empty'
      }
      if (fileId === EMPTY_UUID) {
        return 'syncing'
      }
      return 'active'
    },
    isEmpty (fileId) {
      return fileId === EMPTY_UUID
    },
  },
  data () {
    return {
      data: [
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  padding: 0 40px;
}
.import-document {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  & > .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
    & > .invoice-no {
      font-size: 26px;
      font-weight: bold;
      margin-right: 10px;
    }
    & > .sillar {
      font-size: 14px;
      color: #666;
    }
  }
  & > .info {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 10px;
    & > .shipping-date {
      font-size: 16px;
    }
  }
  & > .documents {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
