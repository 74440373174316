import { createStore } from 'vuex'
import { api } from '@/utils'

const store = createStore({
  state: {
    user: null,
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.user = userInfo
    },
  },
  actions: {
    async loadUserInfo ({ commit }) {
      const res = await api('GET', '/me')
      if (res.status === 200) {
        const user = res.data
        commit('setUserInfo', user)
      } else {
        commit('setUserInfo', null)
      }
    },
  },
  modules: {

  }
})

export default store
