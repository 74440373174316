import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

const app = createApp(App)
app.use(store)
app.use(router)

const mixin = require('@/mixin').default
app.mixin(mixin)
app.mount('#app')
