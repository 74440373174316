<template>
  <div class="container">
    <TopBar />
    <slot />
  </div>
</template>

<script>
import TopBar from './TopBar.vue'

export default {
  // eslint-disable-next-line
  name: 'Container',
  components: {
    TopBar,
  }
}
</script>

<style scoped lang="scss">
</style>
