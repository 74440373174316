import { api } from '@/utils'

export default {
  computed: {
    user () {
      return this.$store.state.user
    },
  },
  methods: {
    api,
  }
}
